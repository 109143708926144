<template>
  <div>
    <div class="head py-4 mb-10">
      <h1
        class="text-center top-text blog"
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        Blog
      </h1>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'px-16 py-4'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <div>
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1637746489/pasted_image_0_zhzo6a.png"
            alt=""
            width="100%"
            style="max-height: 28rem;width: 100%;object-fit: contain"
          />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: Nov 24, 2021</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link to="/blog/the-danger-of-impotent-vaccines">
              The Danger of Impotent Vaccines
            </router-link>
          </h2>
          <p class="">
            Courtesy of the COVID-19, people have become widely interested in
            the subject of vaccines. And, with the emergence of a variety of
            vaccines available, this development has further led to two
            pronounced divisive categories; the anti-vaxxers and the pro-vaccine
            in the grand scheme of things. On the 23rd of March 2021...
          </p>
          <h5>
            <router-link to="/blog/the-danger-of-impotent-vaccines">
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />
        <div class="mt-10">
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1637746491/virolog-coducting-experiment-course-coronavirus-pandemic-with-micropipette-chemist-modern-laboratory-doing-research-using-dispenser-during-global-epidemic-with-covid-19_xdmyay.jpg"
            alt=""
            width="100%"
            style="max-height: 28rem;width: 100%;object-fit: contain"
          />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: Nov 24, 2021</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link to="/blog/the-role-of-storage-in-lab-operation">
              The Role of Storage in Lab Operation
            </router-link>
          </h2>
          <p class="">
            According to the CDC, 14 billion lab tests are ordered annually, and
            70% of medical decisions depend on laboratory results. This data
            gives a background to the level...
          </p>
          <h5>
            <router-link to="/blog/the-role-of-storage-in-lab-operation">
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1637746486/cannabis-marijuana-leaf-closeup_ae4pj6.jpg"
            alt=""
            width="100%"
            style="max-height: 28rem;width: 100%;object-fit: contain"
          />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: Nov 24, 2021</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link to="/blog/applications-of-medical-marijuana">
              Applications Of Medical Marijuana
            </router-link>
          </h2>
          <p class="">
            Marijuana or weed as it’s widely called, hitherto, is a common
            feature of pop culture. The contemporary entertainment media is
            flooded with references to marijuana...
          </p>
          <h5>
            <router-link to="/blog/applications-of-medical-marijuana">
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1637750135/pexels-rodnae-productions-7363196_rjge0g.jpg"
            alt=""
            width="100%"
            style="max-height: 28rem;width: 100%;object-fit: contain"
          />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: Nov 24, 2021</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link to="/blog/making-logistics-smarter-with-iot-and-ai/ml">
              Making Logistics Smarter with IoT and AI/ML
            </router-link>
          </h2>
          <p class="">
            The realisation by corporations of AI's ability to handle the
            complexity of managing a global logistics supply chain is one of the
            main reasons why the adoption...
          </p>
          <h5>
            <router-link to="/blog/making-logistics-smarter-with-iot-and-ai/ml">
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1636104420/medical-doctor-girl-working-with-microscope-young-female-scientist-doing-vaccine-research_sgorph.jpg"
            alt=""
            width="100%"
            style="max-height: 28rem;width: 100%;object-fit: contain"
          />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: Nov 04, 2021</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link
              to="/blog/smart-technologies-in-the-african-medical-sector-Increasing-the-adoption-rate"
            >
              Smart technologies in the African medical sector - Increasing the
              adoption rate.
            </router-link>
          </h2>
          <p class="">
            The African medical sector is one of the most under equipped in the
            world. Many African countries lack proper health care facilities and
            continue to use poor medical equipment to treat sick and dying
            people. Pregnant women and children...
          </p>
          <h5>
            <router-link
              to="/blog/smart-technologies-in-the-african-medical-sector-Increasing-the-adoption-rate"
            >
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1636104027/african-woman-biochemist-researcher-checking-manifestations-vaccine-working-modern-equipped-laboratory-multiethnic-doctors-examining-virus-evolution-using-high-tech-researching-diagnosis_sg4c9g.jpg"
            alt=""
            width="100%"
            style="max-height: 28rem;width: 100%;object-fit: contain"
          />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: Nov 04, 2021</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link to="/blog/iot-and-ai/ml-in-pharmaceutical-process">
              IoT and AI/ML in Pharmaceutical Process
            </router-link>
          </h2>
          <p class="">
            The Pharmaceutical industry is one with many peculiarities and
            stringent regulations in place to ensure the integrity of products
            and processes, and the role IoT and AI/ML play when it comes to
            ensuring efficiency and integrity can’t...
          </p>
          <h5>
            <router-link to="/blog/iot-and-ai/ml-in-pharmaceutical-process">
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1634722714/world-diabetes-day-doctor-holding-patient-hand-s_wccavq.jpg"
            alt=""
            width="100%"
            style="max-height: 28rem;width: 100%;object-fit: contain"
          />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: Oct 20, 2021</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link
              to="/blog/medical-consultant-speaks-on-the-role-of-storage-in-managing-diabetes"
            >
              Medical Consultant Speaks On The Role of Storage In Managing
              Diabetes.
            </router-link>
          </h2>
          <p class="">
            Diabetes (or diabetes mellitus), a chronic disease, is one of the
            leading causes of death in Nigeria. One in seventeen adult Nigerians
            is predicted to be living with the disease - that is about 11.2
            million people in Nigeria. This illness is a result of the body’s
            inability to properly process and use glucose from the food one
            eats...
          </p>
          <h5>
            <router-link
              to="/blog/medical-consultant-speaks-on-the-role-of-storage-in-managing-diabetes"
            >
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1634722663/health-care-doctor-help-concept_qk8hfw.jpg"
            alt=""
            width="100%"
            style="max-height: 28rem;width: 100%;object-fit: contain"
          />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: Oct 18, 2021</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link
              to="/blog/could-poor-storage-be-contributing-to-the-high-diabetes-cases-in-africa-urban-areas"
            >
              Could Poor Storage be Contributing to the High Diabetes Cases in
              Africa Urban Areas?
            </router-link>
          </h2>
          <p class="">
            Carriers of Life had an exclusive interview with Dr Ogunwale, a
            consultant whose services has been benefited from by many top-tier
            hospitals in Nigeria. In this interview, Dr Ogunwale properly
            defined and described diabetes...
          </p>
          <h5>
            <router-link
              to="/blog/could-poor-storage-be-contributing-to-the-high-diabetes-cases-in-africa-urban-areas"
            >
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img
            src="../../assets/blog new/pharmaceuticall.png"
            alt=""
            width="100%"
            style="max-height: 28rem;width: 100%;object-fit: contain"
          />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: Sept 18, 2021</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link
              to="/blog/gricd-works-with-isn-to-assure-product-integrity-and-avert-waste-risk"
            >
              Gricd works with ISN to assure product integrity and avert waste
              risk.
            </router-link>
          </h2>
          <p class="">
            We discovered that each hand-off in the cold chain line opens the
            door for risk. Assumption of product integrity is not enough,
            without reliable and consistent temperature monitoring solutions
            like real-time data loggers actively reporting product...
          </p>
          <h5>
            <router-link
              to="/blog/gricd-works-with-isn-to-assure-product-integrity-and-avert-waste-risk"
            >
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img
            src="../../assets/blog new/190624-culturally-competent-care-3-khn-ew-349p.jpg"
            alt=""
            width="100%"
            style="max-height: 28rem;width: 100%;object-fit: contain"
          />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: Sept 12, 2021</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link
              to="/blog/digitalisation-in-the-retail-pharmaceutical-sector-shelf-life-director-of-pharmacy-suleman-Sule-and-mPharmas-joyce-aderinlewo-talked-about-how-they-are-helping-thousands-of-community-pharmacies-use-data-and-technology-to-optimise-operations"
            >
              Digitalization in the African retail pharmaceutical sector (With
              Shelflife by Field intelligence and mPharma)
            </router-link>
          </h2>
          <p class="">
            Several sectors have over the years benefitted from the disruptive
            power of technology and the convenience of easily accessible...
          </p>
          <h5>
            <router-link
              to="/blog/digitalisation-in-the-retail-pharmaceutical-sector-shelf-life-director-of-pharmacy-suleman-Sule-and-mPharmas-joyce-aderinlewo-talked-about-how-they-are-helping-thousands-of-community-pharmacies-use-data-and-technology-to-optimise-operations"
            >
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img
            src="../../assets/blog new/190624-culturally-competent-care-3-khn-ew-349p.jpg"
            alt=""
            width="100%"
            style="max-height: 28rem;width: 100%;object-fit: contain"
          />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: Sept 12, 2021</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link
              to="/blog/pharmacist-oyce-aderinlewo-speaks-to-carriers-of-life-on-issues-affecting-the-efficiency-of-community-pharmacies-in-africa"
            >
              Pharmacist, Joyce Aderinlewo speaks to Carriers of Life on issues
              affecting the efficiency of Community Pharmacies in Africa
            </router-link>
          </h2>
          <p class="">
            Pharm. Joyce, a pharmacist at MPharma, joined Carriers of Life in a
            discussion concerning the pharmaceutical industry and some of the
            issues currently limiting the operations of community pharmacies...
          </p>
          <h5>
            <router-link
              to="/blog/pharmacist-oyce-aderinlewo-speaks-to-carriers-of-life-on-issues-affecting-the-efficiency-of-community-pharmacies-in-africa"
            >
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg"
            alt=""
            width="100%"
            style="max-height: 28rem;width: 100%;object-fit: contain"
          />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: Sept 12, 2021</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link
              to="/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government"
            >
              Gricd tracks 4.2millions doses of Moderna Vaccines for the
              Nigerian Government
            </router-link>
          </h2>
          <p class="">
            After months of a global lockdown due to the COVID-19 pandemic, the
            world is starting to have a similitude of normalcy thanks to the
            effort of different health organizations across the globe in
          </p>
          <h5>
            <router-link
              to="/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government"
            >
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img
            src="../../assets/blog new/eyecarengonline_1628779252293258.jpg"
            alt=""
            width="100%"
            style="max-height: 28rem;width: 100%;object-fit: contain"
          />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: July 19, 2021</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link
              to="/blog/interview-managing-director-of-kesona-eye-centre-speaks-about-glaucoma-and-its-impact-on-the-health-of-nigerians"
            >
              INTERVIEW: Managing Director Of Kesona Eye Centre Speaks About
              Glaucoma And Its Impact On The Health Of Nigerians
            </router-link>
          </h2>
          <p class="">
            In this exclusive interview with Carriers of Life, Dr Rose Azuike, a
            renowned optometrist, currently, the principal optometrist and
            managing director of Kesona Eye Centre, Lagos, speaks extensively on
            glaucoma, its types and causes, and significantly, its impact on the
            wellbeing of Nigerians
          </p>
          <h5>
            <router-link
              to="/blog/interview-managing-director-of-kesona-eye-centre-speaks-about-glaucoma-and-its-impact-on-the-health-of-nigerians"
            >
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img
            src="../../assets/blog new/eyecarengonline_1628779252293258.jpg"
            alt=""
            width="100%"
            style="max-height: 28rem;width: 100%;object-fit: contain"
          />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: July 19, 2021</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link
              to="/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians"
            >
              Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives
              of Nigerians
            </router-link>
          </h2>
          <p class="">
            Carriers of Life, a blog dedicated to educating Nigerians on the
            best ways to live a healthy life, hosted Dr Rose Azuike on July 19,
            2021, as matters concerning optical care were discussed.
          </p>
          <h5>
            <router-link
              to="/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians"
            >
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img
            src="../../assets/images/baby vaccination.jpg"
            alt=""
            width="100%"
          />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: June 13, 2021</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link
              to="/blog/mote-temperature-monitoring-device-used-to-store-vaccines-for-immunization-services-in-nigeria"
            >
              MOTE Temperature Monitoring Device Used to Store Vaccines For
              Immunization Services In Nigeria
            </router-link>
          </h2>
          <p class="">
            Distributing vaccines in remote communities for mass immunization is
            one of the major challenges of the health industry in Nigeria. This
            is because vaccines are thermally sensitive items, and require an
            uninterrupted cold chain system to stay potent.
          </p>
          <h5>
            <router-link
              to="/blog/mote-temperature-monitoring-device-used-to-store-vaccines-for-immunization-services-in-nigeria"
            >
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img
            src="../../assets/images/covid19-vaccine-waste.jpg"
            alt=""
            width="100%"
          />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: June 13, 2021</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link
              to="/blog/reduce-the-frequency-of-vaccine-waste-with-a-new-and-tested-cold-chain-equipment"
            >
              Reduce the Frequency of Vaccine Waste With A New And Tested Cold
              Chain Equipment
            </router-link>
          </h2>
          <p class="">
            According to UNICEF, vaccines save up to 3 million lives every year.
            Vaccines are believed to be one of the greatest technologies
            tailored towards the sustenance of the human race.
          </p>
          <h5>
            <router-link
              to="/blog/reduce-the-frequency-of-vaccine-waste-with-a-new-and-tested-cold-chain-equipment"
            >
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img
            src="../../assets/images/covid oon deep oce.jpg"
            alt=""
            width="100%"
          />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: June 13, 2021</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link
              to="/blog/the-secret-of-covid-19-vaccine-preservation:-health-workers-adopt-new-cold-chain-equipment-to-store-vaccines-at-appropriate-temperatures"
            >
              The Secret Of COVID-19 Vaccine Preservation: Health Workers Adopt
              New Cold Chain Equipment to Store Vaccines at Appropriate
              Temperatures
            </router-link>
          </h2>
          <p class="">
            Current records show that as of May 2021, Nigeria recorded 166,098
            COVID-19 cases and 2071 deaths. Also, about 156,528 individuals were
            reported to have recovered from the coronavirus. Vaccines play an
            important role in reducing the death toll in epidemic, pandemics and
            other degrees of disease outbreaks.
          </p>
          <h5>
            <router-link
              to="/blog/the-secret-of-covid-19-vaccine-preservation:-health-workers-adopt-new-cold-chain-equipment-to-store-vaccines-at-appropriate-temperatures"
            >
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img src="../../assets/images/image 11 (1).png" alt="" width="100%" />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="ma-0" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: April 1, 2019</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link
              to="/blog/technology-firm-introduces-healthcare-product"
            >
              Technology Firm Introduces Healthcare Product
            </router-link>
          </h2>
          <p class="">
            A technology firm, Gricd, has built a smart cold chain box to
            actively keep temperature sensitive items regulated during
            transportation and storage. The solution was created to improve the
            current method of transporting temperature sensitive items as well
            as ensuring the safety of the items...
          </p>
          <h5>
            <router-link
              to="/blog/technology-firm-introduces-healthcare-product"
            >
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img src="../../assets/images/image 11 (3).png" alt="" width="100%" />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: April 29, 2019</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link
              to="/blog/curbing-losses-in-the-food-value-chain-in-africa"
            >
              Curbing Losses In The Food Value Chain In Africa
            </router-link>
          </h2>
          <p class="">
            According to the Food and Agricultural Organisation of the United
            Nations (FAO), “the lack of reliable and adequate cold chain
            facilities in sub-Saharan Africa is one of the main causes of losses
            of perishable products, which are estimated to be about 25-30
            percent for animal products ...
          </p>
          <h5>
            <router-link
              to="/blog/curbing-losses-in-the-food-value-chain-in-africa"
            >
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />

        <div class="mt-10">
          <img src="../../assets/images/image 11 (4).png" alt="" width="100%" />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="" style="color:#28A84A; font-weight:500">News</p>
          <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: March 7, 2019</p>
          </span>
        </div>
        <div class="bottom-part">
          <h2 class="pb-8 heading">
            <router-link
              to="/blog/the-brilliance-of-nigeria’s-gricd-frij-on-eliminating-food-and-drug-wastes"
            >
              The Brilliance Of Nigeria’s Gricd Frij On Eliminating Food And
              Drug Wastes
            </router-link>
          </h2>
          <p class="">
            A technology firm, Gricd, has built a smart cold chain box to
            actively keep temperature sensitive items regulated during
            transportation and storage. The solution was created to improve the
            current method of transporting temperature sensitive items as well
            as ensuring the safety of the items.
          </p>
          <h5>
            <router-link
              to="/blog/the-brilliance-of-nigeria’s-gricd-frij-on-eliminating-food-and-drug-wastes"
            >
              Read More
            </router-link>
          </h5>
        </div>
        <hr class="mt-10" />
      </v-col>
      <!--  -->
      <v-col cols="12" sm="5"
        ><div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2>Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>

        <div class="heading-title pl-3 pb-10 justify-start">
          <span>
            <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
            <div class="line2" style="border-bottom: 3px solid #28A84A;"></div>
          </span>
          <h2>Our Categories</h2>
        </div>
        <div
          class="right-news mb-6 d-flex"
          v-for="(m, i) in info"
          :key="i"
          style="height:5rem"
        >
          <div
            style="background: rgba(242, 245, 247, 0.6); width:85%"
            class="d-flex align-center"
          >
            <p class=" ma-0 pl-5">
              {{ m }}
            </p>
          </div>
          <div
            style="background: #28A84A; width:15%"
            class="d-flex align-center"
          >
            <p class=" ma-0 mx-auto white--text">0{{ i + 1 }}</p>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import image4 from "../../assets/images/baby vaccination.jpg";
// import image5 from "../../assets/images/covid oon deep oce.jpg";
// import image6 from "../../assets/images/covid19-vaccine-waste.jpg";
export default {
  data: () => ({
    info: ["News", "Health", "Logistics"],
    rightSide: [
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1636104420/medical-doctor-girl-working-with-microscope-young-female-scientist-doing-vaccine-research_sgorph.jpg",
        content:
          "Smart technologies in the African medical sector - Increasing the adoption rate.",
        date: "Nov 04, 2021",
        link:
          "/blog/smart-technologies-in-the-african-medical-sector-Increasing-the-adoption-rate",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1637746486/cannabis-marijuana-leaf-closeup_ae4pj6.jpg",
        content: "Applications Of Medical Marijuana",
        date: "Nov 24, 2021",
        link: "/blog/applications-of-medical-marijuana",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1637746491/virolog-coducting-experiment-course-coronavirus-pandemic-with-micropipette-chemist-modern-laboratory-doing-research-using-dispenser-during-global-epidemic-with-covid-19_xdmyay.jpg",
        content: "The Role of Storage in Lab Operation",
        date: "Nov 24, 2021",
        link: "/blog/the-role-of-storage-in-lab-operation",
      },
    ],
    mySVG: require("../../assets/Logo/search.svg"),
  }),

  metaInfo() {
    return {
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://gricd.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Blog",
                item: "https://gricd.com/blog",
              },
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 5rem 0;
  @media (max-width: 1024px) {
    padding: 3rem 0;
  }
}
h2 {
  font-size: 1.7rem !important;
}
.date {
  p {
    font-size: 0.775rem !important;
  }
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}

.search {
  padding: 1rem 2rem;
  position: relative;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
a {
  text-decoration: none !important;
  color: black !important;
}

.search .v-input__control .v-input__slot {
  position: inherit !important;
}
.v-text-field--outline .v-input__slot {
  border-color: #00000061;
}
</style>
